import { Component, OnInit, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { encryptPassword } from 'app/base/utils';
import { LocalDataStoreService, UserService, LoaderService, ToasterMessageService, CookieStorage } from 'app/base/services';
import { ToastrService } from 'ngx-toastr';
import { locale } from '../../assets/i18n/locale';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['../base/components/user-profile/user-profile.component.scss', './update-email.component.scss']
})
export class UpdateEmailComponent implements OnInit {

  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  public updateEmailForm: UntypedFormGroup;
  public country_locale = new locale();
  public locale;
  private userEmail: string;
  private userID: number;
  private user_role_id: any;
  loader = false;
  formSubmitted = false;
  window: Window & typeof globalThis;

  diverseyEmailDomain = '@diversey.com';
  solenisEmailMigrationSourceEmails = environment.SOLENIS_EMAIL_MIGRATION_SOURCE_EMAILS;
  solenisEmailDomain = 'solenis.com';


  constructor(private toasterService: ToastrService,
    private loaderService: LoaderService,
    private toasterMessageService: ToasterMessageService,
    private _fB: UntypedFormBuilder,
    private localDataStoreService: LocalDataStoreService,
    private cookieStorage: CookieStorage,
    private translate: TranslateService,
    private _userService: UserService,
    @Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
    const userLocale = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    if (userLocale && this.country_locale.dictionary[userLocale]) {
      this.locale = this.country_locale.dictionary[userLocale];
      this.translate.use(this.locale);
    }
  }

  ngOnInit() {
    this.loaderService.loaderState.subscribe((state) => {
      this.loader = state;
    });

    this.updateEmailForm = this._fB.group({
      email: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[\w.'_+-]+@[\w'._+-]+?(\.[a-zA-Z]{2,10}){1,3}$/)]],
      new_email: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[\w.'_+-]+@[\w'._+-]+?(\.[a-zA-Z]{2,10}){1,3}$/)]]
    });

    this.localDataStoreService.currentUserState.subscribe((res) => {
      if (res) {
        this.userEmail = res['email'] || null;
        this.user_role_id = res['user_role_id'];
        this.userID = res['id'];
        this.updateEmailForm.controls['email'].setValue(this.userEmail);
      }
    });
}

onSubmitUpdateEmail(formData) {
  this.formSubmitted = true;
  // console.log('onSubmitUpdateEmail: ', formData);
  if(formData.new_email && formData.new_email.toLowerCase().endsWith('.ru')) {
    // this.toaster.error('Russian country emails(.ru) not allowed');
    this.toasterService.error('Users with email domains from Russia (.ru) are restricted from registering');
    return;
  }
  this.loaderService.show();
  if(this.is_valid_solenis_source_emails(formData.email) && formData.new_email.includes(this.solenisEmailDomain)) {

    const payload = {
      email: formData.email, new_email: formData.new_email
    }
    this.loaderService.show();
    this._userService.updateEmail(payload).subscribe((res) => {
      const message = 'Email update link sent to your new email address succesfully';
      message ? this.toasterService.success(message) : null;
      this.loaderService.hide();
      this.notify.emit();
      
    }, (err) => {
      this.loaderService.hide();
      const errCode = this.getErrorCode(err);
      if (errCode) {
        const message = this.toasterMessageService.getErrorMessage(errCode);
        message ? this.toasterService.error(message) : null;
      }
    });

  } else {
    this.toasterService.error('Please enter valid Solenis email ID');
    this.loaderService.hide();
  }
 
}


  get email() {
    return this.updateEmailForm.get('email') as UntypedFormControl;
  }

  get new_email() {
    return this.updateEmailForm.get('new_email') as UntypedFormControl;
  }

  is_valid_solenis_source_emails(email = '') {
    return this.solenisEmailMigrationSourceEmails.some(source => 
      email.includes(source.suffix)
    );
  }

  getErrorCode(err) {
    if (err && err.error) {
      if (err.error.code) {
        const errorCode = err.error.code;
        return errorCode;
      }
    }
  }

}
