/* eslint-disable security/detect-non-literal-fs-filename */
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, ResponsiveService, CommonService, LocalDataStoreService, CookieStorage, ToasterMessageService } from '../../services';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { locale } from '../../../../assets/i18n/locale';
import { ToastrService } from 'ngx-toastr';
import { flags } from '../../../../assets/images/flags/flags';
import { DOCUMENT } from '@angular/common';
import { CookieKey } from '../../../config/config';
import { environment } from 'environments/environment';
import { isDiverseyAdmin, isPowerAdmin, isRootAdmin } from 'app/base/utils';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  host: {
    '(document:click)': 'hideDrop($event)'
  }
})

export class AppHeaderComponent implements OnDestroy, OnInit {
  public name: any = null;
  public user_role_id: any;
  public date = new Date();
  public responsiveSubscription: Subscription;
  public userSubscription: Subscription;
  public timeSubscription: Subscription;
  public solutionSubscription: Subscription;
  public showResponsive: any;
  public clientLogo: any;
  public solutionName;
  public toggle = false;
  public flags = new flags();
  showtab: boolean;
  showDeactivatetab: boolean;
  showUpdateEmailTab: boolean;
  isAdminUser: boolean;

  public country_locale = new locale();
  public flagIcon = 'en-US';
  public countryName = 'United States';

  public isDiverseyUser = false;
  public isOktaLogin = false;
  public isDiverseyEmail = false;
  public isSolenisMigrationEmail = false;
  public userData: any;

  // variable for showing user profile popup
  showUserProfile: boolean;
  pdfSrc: any = null;
  showTermsAndCond = false;
  conditionsAccepted = true;
  public cookieConsentAccepted = true;
  public updateEmailConsentPopup = false;

  isLoaded = false;
  window: Window;

  constructor(private userService: UserService,
    private router: Router,
    private responsiveService: ResponsiveService,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private localDataStoreService: LocalDataStoreService,
    private toasterMessageService: ToasterMessageService,
    private cookieStorage: CookieStorage,
    @Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
    let locales = this.cookieStorage.getCookie('locale') || 'en-US';
    if (locales && this.country_locale.dictionary[locales]) {
      locales = this.country_locale.dictionary[locales];
      this.translate.use(locales);
    }
    this.responsiveSubscription = this.responsiveService.showResponsive.subscribe(responsive => {
      if (responsive) {
        this.showResponsive = responsive;
      }
    });
  }

  ngOnInit() {
    this.userSubscription = this.userService.userNameSubject.subscribe(userName => {
      if (userName) {
        this.name = userName;
      }
    });
    this.commonService.clientLogoSubscription.subscribe(logo => {
      this.clientLogo = logo;
    });
    this.commonService._solutionSubject.subscribe(solution => {
      if (solution) {
        this.solutionName = solution;
      }
    });
    this.localDataStoreService.currentUserState.subscribe((res) => {
      // console.log('header currentUserState change: ', res);
      
      const userData = res;
      this.userData = res;
      if (userData && userData.id) {
        if (userData.locale) {
          this.flagIcon = userData.locale;
          this.countryName = this.flags.dictionary[userData.locale];
          const dictLocale = this.country_locale.dictionary[userData.locale];
          if (dictLocale) {
            this.translate.use(dictLocale);
          }
        }

        this.isOktaLogin = userData['is_okta_login'];
        this.isDiverseyEmail = (userData['email'].includes('@diversey.com')) ? true : false;
        this.isSolenisMigrationEmail = environment.SOLENIS_EMAIL_MIGRATION_SOURCE_EMAILS.some(source => 
          userData['email'].includes(source.suffix)
        );
        this.name = userData['username'] || null;
        this.user_role_id = userData['user_role_id'];
        this.showUserProfile = false;
        this.conditionsAccepted = userData['term_condition_accepted'] ? userData['term_condition_accepted'] : false;
        
        this.cookieConsentAccepted = (userData['cookie_consent'] && userData['cookie_consent'] != null) ? true : false;
        this.updateEmailConsentCheck();

        if((userData['cookie_consent'] && userData['cookie_consent'] != null)) {
          this.cookieConsentAccepted = true;
          this.cookieStorage.setCookie(CookieKey.COOKIE_CONSENT, userData['cookie_consent']);
        } else {
          this.cookieStorage.clearCookie(CookieKey.COOKIE_CONSENT);
          this.cookieConsentAccepted = false;
        }

        const termsId = userData.term_condition_id || null;
        if ((this.conditionsAccepted === false) && termsId) {
          this.fetchTermsAncCondition(termsId);
        }

        if(this.localDataStoreService.userDetails) {
          this.isDiverseyUser = (this.localDataStoreService.userDetails.email.includes('@diversey.com')) ? true : false;
          this.isSolenisMigrationEmail = environment.SOLENIS_EMAIL_MIGRATION_SOURCE_EMAILS.some(source => 
            this.localDataStoreService.userDetails.email.includes(source.suffix)
          );
        }
        
      } else {
        this.conditionsAccepted = true;
        this.cookieConsentAccepted = true;
        this.userSubscription = this.userService.userNameSubject.subscribe((userName) => this.name = null);
      }
    });
  }

  ngOnDestroy() {
    if (this.responsiveSubscription) {
      this.responsiveSubscription.unsubscribe();
    }
    if (this.timeSubscription) {
      this.timeSubscription.unsubscribe();
    }
    if (this.responsiveSubscription) {
      this.responsiveSubscription.unsubscribe();
    }
    if (this.solutionSubscription) {
      this.solutionSubscription.unsubscribe();
    }
  }

  logout_old() {
    this.toggle = false;
    this.userService.getUserDetails().subscribe((user)=> {
        if(user && user.is_okta_login && user.email != undefined && user.email.includes('@diversey.com')) {
          // console.log('logout okta', user);
          this.userService.logoutOkta(user.email);
        } else {
          // console.log('logout', user);
          this.userService.logout(true,user.email);
          this.cookieStorage.clearCookie('locale');
          this.cookieStorage.clearCookie(CookieKey.COOKIE_CONSENT);
        }
    });
  }

  public logout(): void {
		this.userService.logout_with_idp().subscribe((res) => {
    const data = res['data'];
    if ((data != undefined) && (data.loginInfo != undefined) && (data.loginInfo.idp != undefined) && (data.loginInfo.idp != '')) {
      if (data.loginInfo.idp === 'divokta') {
        this.userService.logoutOkta(null);    // okta-logout
      } else {
        this.deleteUpdateEmailCookieValue();
        this.userService.updateLocalStorage(true);
      }
    } else {
      this.deleteUpdateEmailCookieValue();
      this.userService.updateLocalStorage(true);
    }
		}, (err) => {
      this.deleteUpdateEmailCookieValue();
      this.userService.updateLocalStorage(true);
		});
	}

  hideDrop(e) {
    e.stopPropagation();
    this.toggle = false;
  }

  // open user profile popup
  openUserProfile() {
    this.toggle = false;
    this.showUserProfile = true;
    this.showtab = true;
  }

  // close user profile popup
  closeUserProfile() {
    this.showUserProfile = false;
  }

  switchTabs(event) {
    if (event === 'profile') {
      this.showtab = true;
      this.showDeactivatetab = false;
      this.showUpdateEmailTab = false;
    } else if (event === 'reset') {
      this.showtab = false;
      this.showDeactivatetab = false;
      this.showUpdateEmailTab = false;
    } else if (event === 'deactivate') {
      this.showtab = false;
      this.showDeactivatetab = true;
      this.showUpdateEmailTab = false;
    } else if (event === 'updateEmail') {
      this.showtab = false;
      this.showDeactivatetab = false;
      this.showUpdateEmailTab = true;
    }
  }

  changePassword() {
    if(this.isOktaLogin) {
      // this.window.location.href = environment.OKTA_DOMAIN + '/enduser/settings';
      this.window.open(environment.OKTA_DOMAIN + '/enduser/settings');
    } else {
      this.toggle = false;
      this.showUserProfile = true;
      this.showtab = false;
      this.showDeactivatetab = false;
      this.showUpdateEmailTab = false;
    } 
  }

  updateEmail() {
    if(this.isSolenisMigrationEmail && !this.checkDiverseyRootOrPowerAdmin()) {
      this.toggle = false;
      this.showUserProfile = true;
      this.showtab = false;
      this.showDeactivatetab = false;
      this.showUpdateEmailTab = true;
    }
  }

  showDeactivateModal() {
    this.toggle = false;
    this.showUserProfile = true;
    this.showtab = false;
    this.showDeactivatetab = true;
    this.showUpdateEmailTab = false;
  }

  home() {
    this.router.navigate(['dashboard']);
  }

  fetchTermsAncCondition(id) {
    this.userService.getTerms(id).subscribe((res) => {
      const { success, data } = res;
      if (res && success && data) {
        this.pdfSrc = data['current_version'] || null;
      }
    }, (err) => {
      this.conditionsAccepted = true;
      this.pdfSrc = null;
      const errCode = this.getErrorCode(err);
      if (errCode) {
        const message = this.toasterMessageService.getErrorMessage(errCode);
        message ? this.toastrService.error(message) : null;
      }
    });
  }

  getErrorCode(err) {
    if (err && err.error) {
      if (err.error.code) {
        const errorCode = err.error.code;
        return errorCode;
      }
    }
  }

  showPopUp(event) {
    this.conditionsAccepted = event;
  }

  onProgress(event) {
    this.isLoaded = false;
  }

  afterLoadComplete(pdfData: any) {
    this.isLoaded = true;
  }

  toggleDropdown(e) {
    e.stopPropagation();
    this.toggle = !this.toggle;
  }

  closeProfilePopUp(value: boolean) {
    this.toggle = value;
  }

  showHideDeactivateBtn() {
    this.isAdminUser = (this.user_role_id === 1 || this.user_role_id === 4) ? false : true;
    return this.isAdminUser;
  }

  hideConsentPopup() {
    this.cookieConsentAccepted = true;
  }

  modifyCookies() {
    // this.document.cookie = "OptanonAlertBoxClosed =;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    // this.document.cookie = "OptanonConsent =;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    // this.document.cookie = "cookie_consent =;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    // CookieKey.COOKIE_CONSENT
    this.cookieStorage.clearCookie(CookieKey.COOKIE_CONSENT);    
    this.cookieConsentAccepted = false;
    // this.window.location.reload();
  }

  checkDiverseyRootOrPowerAdmin() {
    const { id, accessible_nodes, org_id, user_role_id } = this.userData;
    return (isPowerAdmin(this.userData.user_role_id) || isRootAdmin(id, user_role_id, accessible_nodes, org_id));
  }

  updateEmailConsentCheck() {
    if(this.userData) {
        this.updateEmailConsentPopup = (this.isSolenisMigrationEmail && !this.checkDiverseyRootOrPowerAdmin()) ? true : false;
        
    }
  }

  hideEmailConsentPopup() {
    this.updateEmailConsentPopup = false;
  }

  proceedEmailUpdateAndHideConsentPopup() {
    this.updateEmailConsentPopup = false;
    this.updateEmail();
  }
  public deleteUpdateEmailCookieValue() {
    const cookieKeyUpdateEmail = CookieKey.UPDATE_EMAIL_CONSENT_POPUP + '_' + this.userData['id'];
    this.cookieStorage.clearCookie(cookieKeyUpdateEmail);
  }

}
